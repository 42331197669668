<template>
	<div class="m-auto w-full h-full fixed inset-0 z-50 opacity-75 bg-black">
		<div class="w-full h-full">
			<WaveLogo
				class="h-full m-auto absolute inset-0 text-white"
				style="height: 58px;"
			/>
		</div>
		<BaseLoader
			class="m-auto absolute inset-0 wave-loader-animation"
			role="status"
		>
			<span class="sr-only">Loading...</span>
		</BaseLoader>
	</div>
</template>

<script>
	/* eslint max-len: ["error", { "ignoreUrls": true }] */
	/* eslint max-len: ["error", { "ignoreComments": true }] */
	/* eslint max-len: ["error", { "ignoreStrings": true }] */
	/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
	/* eslint max-len: ["error", { "code": 200 }] */
	/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["bar"] }] */

	// @ is an alias to /src
	import BaseLoader from "@/components/BaseLoader.vue";
	import WaveLogo from "@/components/WaveLogo.vue";

	export default {
		name: "wave-loader",
		components: {
			BaseLoader,
			WaveLogo
		},
		props: {
			opacity: [String, Number],
			required: () => false,
			default: () => 0.8
		}
	};
</script>

<style lang="scss">
	.wave-loader-animation {
		-webkit-animation: rotation 1s infinite linear;
	}

	@-webkit-keyframes rotation {
		from {
			-webkit-transform: rotate(0deg);
		}
		to {
			-webkit-transform: rotate(359deg);
		}
	}
</style>
